<template>
  <div class="modal-wrapper" @open="updateRandomImage">
    <div v-bind:style="this.hidden ? 'display: none' : ''" class="modal">
<!--      <div v-if="showRedirectModal" class="redirect-message">-->
<!--        {{ this.redirectMessage }}-->
<!--      </div>-->
      <div v-if="!showRedirectModal" class="modal-bg" >
        <transition name="bounce">
          <div class="modal-content" v-if="show && brandData !== null">
<!--            <button class="modal-exit-button" @click="closeModal">&#x2715;</button>-->
            <div class="modal-content--inner"
              v-bind:style="{ 'background-color': modalBackgroundColor ? modalBackgroundColor : '' }">
              <img class="modal-logo" :src="this.modalLogo || randomImage" alt=""/>
              <h1 class="modal-title" v-bind:style="{ 'color': modalTitleColor ? modalTitleColor : '' }">
                {{ this.modalTitle || "You've Hit the Jackpot!" }} </h1>
              <h2 class="modal-subtitle" v-if="!enableBrandOffer" v-bind:style="{ 'color': modalSubtitleColor ? modalSubtitleColor : '' }">
                {{ this.modalSubtitle || "Claim Your Offer Now!" }} </h2>
              <div class="brand-wrapper" v-if="enableBrandOffer" :data-brand="brandData.brandData[0].brand_id">
                <div class="brand-value">
                  <div class="brand-logo">
                    <a><img :src="brandData.brandData[0].brand_logo" alt="brand-logo"/></a>
                  </div>
                  <div class="brand-offer" v-bind:style="{ 'color': modalBrandTextColor ? modalBrandTextColor : '' }"
                    v-html="brandData.brandData[0].brand_offer"></div>
                </div>
                <div class="tcs" v-html="brandData.brandData[0].custom_tcs"></div>
              </div>
              <div class="winner-message"
                v-bind:style="{ 'color': modalWinnerMessageColor ? modalWinnerMessageColor : '' }">
                {{ this.modalWinnerMessage || "Congrats! All we need now is your email and name to send this deal your way."
                }}
              </div>
              <!-- <div id="hubform" v-once></div> -->
              <form @submit.prevent="submitForm">
                <div class="name-email">
                  <div class="input-name">
                    <label for="first name"><input v-model="form.name" :placeholder-color="modalInputTextColor" class="input"
                    :placeholder="modalInputFirstField || 'First Name'" required type="text"
                         v-bind:style="{ 'color': modalInputTextColor ? modalInputTextColor : '' }"></label>
                    <!-- <label for="second name"><input v-model="form.surname" :placeholder-color="modalInputTextColor" class="input"
                         :placeholder="modalInputSecondField || 'last name'" required type="text" v-bind:style="{ 'color': modalInputTextColor ? modalInputTextColor : '' }"></label> -->
                         <label for="phone">
                          <input v-model="form.phone" class="input"
                                 :placeholder="modalInputSecondField || 'Phone Number'" required type="text"
                                 v-bind:style="{'width': '100%', 'color': modalInputTextColor ? modalInputTextColor : '' }" pattern="\d{6,18}" >
                         </label>
                        </div>
                  <!-- <div class="input-mail"><label for="email"><input v-model="form.email" class="input" :placeholder="modalInputThirdField || 'email'" required
                    type="email" v-bind:style="{'width': '100%', 'color': modalInputTextColor ? modalInputTextColor : '' }" @focus="feedback = ''" @keyup="isEmailValid"></label></div> -->
                  <label for="subject"><input v-model="form.subject" type="text" name="subject" class="subject required"></label>
                </div>
                <div class="form-feedback" v-if="feedback">{{ this.feedback }}</div>
                <div class="checkbox">
                  <!-- eslint-disable max-len -->
                  <label for="custom-checkbox" class="custom-checkbox-label">
                  <input type="checkbox" required class="custom-checkbox"><span
                    v-bind:style="{ 'color': modalCheckboxTextColor ? modalCheckboxTextColor : '' }">{{
                      this.modalCheckboxText
                      || `By ticking the box you agree to receive weekly newsletters from us for the latest on bookmakers offers. Privacy Policy`}}</span></label>
                  <br>
                </div>
                <div class="checkbox">
                  <!-- eslint-disable max-len -->
                  <label for="custom-checkbox" class="custom-checkbox-label">
                    <input type="checkbox" required class="custom-checkbox"><span
                    v-bind:style="{ 'color': modalCheckboxTextColor ? modalCheckboxTextColor : '' }">{{
                      this.modalCheckboxTextSecond
                      || `By clicking the enter button, you confirm that you are 18+ and permitted to visit our website, you declare that you have read our Privacy Policy notice`}}</span></label>
                  <br>
                </div>
                <div class="form-buttom">
                  <button class="button button--form"
                          v-bind:style="{ 'background-color': modalFormButtonBackgroundColor ? modalFormButtonBackgroundColor : '' }"
                          :class="{ loading: submitting }">
                    <span class="button--form__text"
                          v-bind:style="{ 'color': modalFormButtonTextColor ? modalFormButtonTextColor : '' }">
                      {{ this.modalFormButtonText || "Submit" }}</span>
                    <div v-if="submitting" class="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

import image1 from '@/assets/images/ccrown.png';
import image2 from '@/assets/images/cherry.png';
import image3 from '@/assets/images/diamond.png';
import image4 from '@/assets/images/gold.png';

export default {
  name: 'Modal',
  props: ['brandData', 'pageData', 'trackid'],
  data() {
    return {
      randomImage: '',
      images: [image1, image2, image3, image4],
      show: false,
      submitting: false,
      modalData: null,
      modalBackgroundColor: null,
      modalTitle: null,
      modalTitleColor: null,
      enableBrandOffer: null,
      modalSubtitle: null,
      modalSubtitleColor: null,
      modalBrandTextColor: null,
      modalWinnerMessage: null,
      modalWinnerMessageColor: null,
      modalInputTextColor: null,
      modalInputFirstField: null,
      modalInputSecondField: null,
      modalInputThirdField: null,
      modalFormButtonText: null,
      modalFormButtonTextColor: null,
      modalFormButtonBackgroundColor: null,
      modalCheckboxTextColor: null,
      modalCheckboxText: null,
      modalLogo: null,
      modalCheckboxTextSecond: null,
      geo: null,
      vertical: null,
      networkName: null,
      pageAb: null,
      hidden: false,
      redirectMessage: 'Success! Check your inbox and confirm your email address to receive special offers.',
      showRedirectModal: false,
      privatePolicyUrl: null,
      form: {
        email: '',
        name: '',
        surname: '',
        // correct: false,
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        subject: '',
      },
      feedback: '',
      // TODO Change hubspot form details to api key if needed later
      portalId: '25231212',
      formId: '5eebc9f4-c574-42cf-8546-90af6a32f80a',
    };
  },
  methods: {
    getRandomImage() {
      const randomIndex = Math.floor(Math.random() * this.images.length);
      return this.images[randomIndex];
    },
    updateRandomImage() {
      this.randomImage = this.getRandomImage();
    },
    closeModal() {
      // close the modal
      this.hidden = true;
      setTimeout(() => {
        this.show = false;
        window.location.reload();
      }, 400);
    },
    // isEmailValid() {
    //   this.form.correct = this.form.reg.test(this.form.email);
    // },
    submitForm() {
      if (this.form.subject !== '') {
        const data = {
          userAgent: navigator.userAgent || '',
        };
        this.axios.post(`${process.env.VUE_APP_API}/bots/v1/record`, data);
        this.hidden = true; return;
      }
      if (this.submitting) return;
      // if (!this.form.correct) {
      //   this.feedback = 'Incorrect email address';
      //   return;
      // }
      this.submitting = true;
      this.saveClick();
      // const payload = {
      //   email: this.form.email,
      //   name: this.form.name,
      //   trackid: this.brandData !== null ? this.brandData.trackid : '',
      //   scrt: '2ec353de-f053-11eb-9a03-0242ac130003',
      // };
      // this.emailSubmit(payload);
      // const formData = {
      //   submittedAt: Date.now(),
      //   fields: [
      //     {
      //       objectTypeId: '0-1',
      //       name: 'email',
      //       value: this.form.email !== null ? this.form.email : '',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'firstname',
      //       value: this.form.name !== null ? this.form.name : '',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'lastname',
      //       value: this.form.surname !== null ? this.form.surname : '',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'trackid',
      //       value: this.trackid !== null ? this.trackid : 'trackidMissing',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'gclid',
      //       value: this.getParameterByName('gclid') || 'false',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'utm_campaign',
      //       value: this.getParameterByName('utm_campaign') || 'false',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'utm_source',
      //       value: this.getParameterByName('utm_source') || 'false',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'utm_medium',
      //       value: this.getParameterByName('utm_medium') || 'false',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'utm_term',
      //       value: this.getParameterByName('utm_term') || 'false',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'utm_content',
      //       value: this.getParameterByName('utm_content') || 'false',
      //     },
      //     {
      //       objectTypeId: '0-1',
      //       name: 'n123casinos___consent_checkbox',
      //       value: 1,
      //     },
      //   ],
      //   context: {
      //     // hutk: this.hubspotCookie(),
      //     pageUri: window.location.origin,
      //   },
      // };
      // this.axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formId}`, formData)
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    // disable email sent in db
    /* async emailSubmit(payload) {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        await this.axios.post(`${process..env.VUE_APP_CLICK_API}/api/v1/user/save`, payload, config);
      } catch (error) {
        console.log(error);
      }
    }, */
    getParameterByName(paramName, url = window.location.href) {
      const name = paramName.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return false;
      if (!results[2]) return false;
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    async saveClick() {
      try {
        if (this.brandData === null || this.brandData.brandData === undefined) return;
        if (this.brandData.brandData.length === 0) return;
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const data = {
          eventType: 'click',
          trackid: this.trackid || '',
          brandId: this.brandData.brandData[0].brand_id || '0',
          clickSource: this.form.phone || '',
          brandPos: '1',
          brandName: this.brandData.brandData[0].brand_name || '',
          brandTableId: this.brandData.tableId || '0',
          brandTableName: this.brandData.tableName || '',
          brandOfferName: this.brandData.brandData[0].brand_offer_name || '',
          networkName: this.networkName || '',
          domain: window.location.hostname || '',
          page: '/',
          pageId: '0',
          utmSource: this.getParameterByName('utm_source') || 'false',
          utmMedium: this.getParameterByName('utm_medium') || 'false',
          utmTerm: this.getParameterByName('utm_term') || 'false',
          utmContent: this.getParameterByName('utm_content') || 'false',
          utmCampaign: this.getParameterByName('utm_campaign') || 'false',
          gclid: this.getParameterByName('gclid') || 'false',
          keyword: this.getParameterByName('keyword') || 'false',
          matchtype: this.getParameterByName('matchtype') || 'false',
          device: this.getParameterByName('device') || 'false',
          userAgent: navigator.userAgent || '',
          adpos: this.getParameterByName('adpos') || 'false',
          geo: this.geo || '',
          ftdValue: this.brandData.brandData[0].ftd_value || '0',
          vertical: this.vertical || '',
          ab: this.pageAb || '',
          activeAgg: '',
          blockDetected: false,
          wasRightClick: 0,
        };
        await this.axios.post(`${process.env.VUE_APP_CLICK_API}/api/v1/user/save`, JSON.stringify(data), config);
        this.saveToGtm(data);
        this.redirectUser();
      } catch (error) {
        console.log(error);
      }
    },
    redirectUser() {
      const url = new URL(this.brandData.brandData[0].custom_brand_clickout);
      const tlimit = this.brandData.brandData[0].trackid_length !== undefined ? parseInt(this.brandData.brandData[0].trackid_length, 10) : 32;
      const uuidPassed = Number.isInteger(tlimit) ? this.trackid.slice(0, tlimit) : this.trackid;
      let modifiedUrl = false;
      this.showRedirectModal = true;

      if (url.href.includes('%7Btrackid%7D')) {
        modifiedUrl = url.href.replace('%7Btrackid%7D', uuidPassed);
      }

      if (url.href.includes('{trackid}')) {
        modifiedUrl = url.href.replace('{trackid}', uuidPassed);
      }

      if (modifiedUrl !== false) {
        url.href = modifiedUrl;
      }

      url.searchParams.append('trackid', uuidPassed);

      setTimeout(() => {
        window.location.href = url;
      }, 1000);
    },
    saveToGtm(data) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'formSubmittedData',
        formSubmittedData: data,
      });
    },
    // hubspotCookie() {
    //   console.log(this.$cookies);
    //   return this.$cookies.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, '$1');
    //   // document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, '$1');
    // },
  },
  mounted() {
    this.randomImage = this.getRandomImage();
    this.modalBackgroundColor = this.pageData.modalBackgroundColor;
    this.modalTitle = this.pageData.modalTitle;
    this.modalTitleColor = this.pageData.modalTitleColor;
    this.enableBrandOffer = this.pageData.enableBrandOffer;
    this.modalSubtitle = this.pageData.modalSubtitle;
    this.modalSubtitleColor = this.pageData.modalSubtitleColor;
    this.modalBrandTextColor = this.pageData.modalBrandTextColor;
    this.modalWinnerMessage = this.pageData.modalWinnerMessage;
    this.modalWinnerMessageColor = this.pageData.modalWinnerMessageColor;
    this.modalInputTextColor = this.pageData.modalInputTextColor;
    this.modalInputFirstField = this.pageData.modalInputFirstField;
    this.modalInputSecondField = this.pageData.modalInputSecondField;
    this.modalInputThirdField = this.pageData.modalInputThirdField;
    this.modalFormButtonText = this.pageData.modalFormButtonText;
    this.modalFormButtonTextColor = this.pageData.modalFormButtonTextColor;
    this.modalFormButtonBackgroundColor = this.pageData.modalFormButtonBackgroundColor;
    this.modalCheckboxTextColor = this.pageData.modalCheckboxTextColor;
    this.modalCheckboxText = this.pageData.modalCheckboxText;
    this.modalLogo = this.pageData.modalLogo;
    this.modalCheckboxTextSecond = this.pageData.modalCheckboxTextSecond;
    this.networkName = this.pageData.networkName;
    this.geo = this.pageData.geo;
    this.vertical = this.pageData.vertical;
    this.pageAb = this.pageData.pageAb;
    this.privatePolicyUrl = this.pageData.privatePolicyUrl;
    setTimeout(() => {
      this.show = true;
    }, 100);
  },
};

</script>

<style lang="scss">
.button--form {
  position: relative;

  &.loading {
    cursor: not-allowed;
    opacity: 0.5;

    W .button--form__text {
      opacity: 0;
    }
  }

  .lds-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(.45);
  }
}

.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.input {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: .5rem;
  color: #8655A3;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--placeholder-color);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--placeholder-color);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--placeholder-color);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: var(--placeholder-color);
}

.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 1024px){
    background-color: #17133E;
  }
}

.redirect-message {
  z-index: 999;
  background: white;
  position: absolute;
  padding: 3rem;
  border: 6px #e5cb79 solid;
  text-align: center;
  border-radius: 3px;
  background: #007900;
  color: white;
  font-size: 1.5rem;
  max-width: 640px;
}

.modal-exit-button {
  color: #000;
  z-index: 2;
  position: absolute;
  right: 0;
  @media(max-width:1024px){
    display: none;
  }
}

/* Modal Content */
.modal-content {
  width: 608px;
  max-width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 0.2rem);
    height: calc(100% + 0.2rem);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background: #FFB900;
    box-shadow: 0px 0px 20px 0px rgba(205, 162, 203, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3e499', endColorstr='#c89838', GradientType=1);
    @media(max-width: 1024px){
      display: none;
    }
  }
}

.modal-content--inner {
  position: relative;
  z-index: 1;
  background-color: #17133E;
  border-radius: 12px;
  padding: 2rem;
  max-height: calc(100vh - 3rem);
  overflow: auto;
  margin-top: 1rem;
}

.modal-logo {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 150px;
  z-index: 2;
  padding-bottom: 2rem;
  @media(max-width: 1024px){
    max-width: 90px;
    padding-bottom: .5rem;
  }
}

.modal-title {
  color: #fff;
  font-family: "DM Sans";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0 0 3rem;
  text-align: center;
  letter-spacing: 2px;
  @media(max-width: 1024px) {
    margin-bottom: .5rem;
  }
}

.modal-subtitle{
  text-align: center;
  font-family: "DM Sans";
  font-size: 28px;
  color: #FFB900;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 2rem;
  @media(max-width: 1024px){
    font-size: 20px;
    padding-bottom: 0.5rem;
  }

  }

.brand-wrapper {
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.brand-value {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.tcs {
  text-align: center;
  margin-top: 1rem;
  color: #ccc;
  font-size: 10px;
}

.brand-logo {
  img {
    display: block;
    width: 200px;
  }
}

.brand-offer {
  flex: 1;
  margin-left: 1rem;
  font-size: 1.75em;
  line-height: 1.25;
  color: #402689;
  align-self: center;
  text-align: center;
}

.winner-message {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1rem 0 3rem;
  text-align: center;
  color: #fff;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, .5);
  @media(max-width: 1024px){
    font-size: 16px;
  }
}

.checkbox {
  color: #fff;
  font-size: .85em;
  margin-top: 1rem;
  text-align: left;
  font-family: 'Roboto';
  font-weight: 400;
  margin-left: 1rem;
  margin-right: 1rem;
  span {
    margin-left: 10px;
    text-align: center;
    color: #fff;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media(max-width: 1024px) {
      font-size: 10px;
    }

    :visited {
      color: #fff;
    }
  }
}
.custom-checkbox {
  background-color: #372759;
  appearance: none;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border: 1px solid #8655A3;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 4px;
}

.custom-checkbox:checked {
  background-color: #F9AB31;
}
input.subject.required {
  display: none;
}

.brand-logo {
  border-radius: 10px;
  overflow: hidden;
}

.name-email {
  align-items: center;
  @media(max-width: 1024px){
  }
  .input {
    margin: auto;
    background-color: #372658;
    border: 1px solid #8655A3;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    border-radius: 10px;
  }
}

.input-name{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .input{
    width: 100%;
  }
  label{
    width: 100%;
    margin: 0 1rem;
    @media(max-width: 1024px){
      margin: .5rem auto;
    }
  }
  @media(max-width: 1024px){
    flex-direction: column;
  }
}

.input-mail{
  display: flex;
  justify-content: center;
  margin: 1rem auto 0;
  label{
    width: 100%;
    margin: 0 1rem;
    @media(max-width: 1023px) {
      margin-right: 0;
      margin-left: 0;
    }
  }
  @media(max-width: 1024px) {
    max-width: 100%;
    margin: .5rem auto 0;
  }
}

.form-buttom {
  margin-top: 1rem;
  text-align: center;
}

.button--form {
  font-size: 1.5em;
}

.modal-exit-button{
  background: #372658;
  color: #8655A3;
  border-radius: 50%;
  cursor: pointer;
}

.form-feedback {
  text-align: center;
  color: #fff;
  margin-top: 1rem;
}

@media (max-width: 1023px) {
  .modal-content {
    max-width: 500px;
    width: calc(100% - 2rem);
    margin: 0 auto;
  }

  .brand-wrapper {
    flex-direction: column;
  }

  .brand-offer {
    margin: .5rem 0 0;
    text-align: center;
    line-height: 1;
  }
  .name-email {
    flex-direction: column;

    .input {
      flex: none !important;
      padding: .7rem;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  .modal-content--inner {
    padding: 2rem 1rem 1rem;
  }
}

@media (max-width: 500px) {
  .modal-logo {
    width: 150px;
    top: -25px;
  }

  .brand-logo {
    img {
      width: 150px;
    }
  }

  .brand-offer {
    font-size: 1.25em;
  }

  .modal-content--inner {
    padding: 1.5rem 1rem 1rem;
  }

  .winner-message {
    margin-bottom: 1rem;
  }

  .name-email .input {
    padding: .75rem;
    font-size: 14px;
  }

  .brand-wrapper {
    padding: .5rem;
  }

  button.button.button--form {
    font-size: 1em;
    width: 100%;
    border-radius: 5px;
  }

  .checkbox {
    font-size: 12px;
  }
}
</style>
