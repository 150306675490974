// import './css/normalize.scss';
// import './css/fonts.scss';
// import './css/core.scss';
//
// import Vue from 'vue';
// import VueConfetti from 'vue-confetti';
// import axios from 'axios';
// import VueAxios from 'vue-axios';
// import VueCookies from 'vue-cookies';
// import App from './App.vue';
//
// Vue.config.productionTip = false;
// Vue.use(VueAxios, axios);
// Vue.use(VueConfetti);
// Vue.use(VueCookies);
// Vue.$cookies.config('7d');
//
// // set global cookie
// Vue.$cookies.set('theme', 'default');
// Vue.$cookies.set('hover-time', '1s');
//
// new Vue({
//   render: (h) => h(App),
// }).$mount('#app');

import './css/normalize.scss';
import './css/fonts.scss';
import './css/core.scss';

import { createApp } from 'vue';
import VueConfetti from 'vue-confetti';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import App from './App.vue';

const app = createApp(App);

// Use plugins with the app instance
app.use(VueAxios, axios);
app.use(VueConfetti);
app.use(VueCookies);

// Set global cookie configuration
app.config.globalProperties.$cookies.config('7d');
app.config.globalProperties.$cookies.set('theme', 'default');
app.config.globalProperties.$cookies.set('hover-time', '1s');

// Mount the app
app.mount('#app');
